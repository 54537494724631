import { post, get, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const show = (clientId, ticketId) => get(`admin/client/${clientId}/ticket/${ticketId}`)

export const create = (clientId, values) => post(`${clientId}/ticket`, values);

export const autocomplete = (page, perPage, search, sortBy, descending, params) =>
  getPaginated(`admin/user/autocomplete`, page, perPage, search, sortBy, descending, { role: 'super-admin' });

export const addComment = (ticketId, comment) => post(`ticket/${ticketId}/comment`, { comment });

export const getComments = (ticketId) => get(`ticket/${ticketId}/comment`);

export const index = (clientId, ...args) => getPaginated(`admin/client/${clientId}/ticket`, ...args);

export const updateTicket = (clientId, ticketId, assignedUserId, ticketStatusId, ticketPriorityId) => put(`admin/client/${clientId}/ticket/${ticketId}`,
  {
    assignedUserId,
    ticketStatusId,
    ticketPriorityId
  });
