<template>
  <TicketsListLayout ref="layout"
                     :title="$t('ticket.tickets.menuTitle')"
                     :index-request="indexRequest"
                     :crud-headers="crudHeaders"
                     detail-to="tickets.show"
  />
</template>

<script>
import TicketsListLayout from '@/modules/tickets/components/TicketsListLayout.vue';
import { index } from '@/modules/tickets/api/ticket.js';
import { mapGetters } from 'vuex';

export default {
  name: 'TicketsList',
  components: { TicketsListLayout },
  computed: {
    ...mapGetters('settings', { clientId: 'getCurrentClientId' }),
    crudHeaders() {
      return [
        {
          value: 'id',
          language: 'ticketId',
        },
        {
          value: 'ticketStatusId',
          language: 'status',
        },
        {
          value: 'subject',
          language: 'subject',
        },
        {
          value: 'storeName',
          language: 'store'
        },
        {
          value: 'locationType',
          language: 'location',
        },
        {
          value: 'ticketPriorityId',
          language: 'priority',
        },
        {
          value: 'reportedBy',
          language: 'reportedBy',
        },
        {
          value: 'createdAt',
          language: 'creationDate',
        },
        {
          value: 'updatedAt',
          language: 'lastUpdated',
        },
        {
          value: 'assignedTo',
          language: 'assignedTo'
        }
      ];
    },
  },
  methods: {
    indexRequest(...args) {
      return index(this.clientId, ...args);
    },
  },
};
</script>
