var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-crud-layout',{attrs:{"with-search":false},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.title))]},proxy:true},{key:"view.list",fn:function(){return [_c('k-crud-table',{ref:"table",attrs:{"headers":_vm.crudHeaders,"index-request":_vm.indexRequest,"language-prefix":"ticket.crudHeaders","resource":"ticket"},scopedSlots:_vm._u([{key:"item.ticketStatusId",fn:function(ref){
var ticketStatusId = ref.item.ticketStatusId;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("ticket.ticketStatus." + (_vm.getTicketStatus(ticketStatusId))))))])]}},{key:"item.assignedTo",fn:function(ref){
var assignedTo = ref.item.assignedTo;
return [_c('span',[_vm._v(_vm._s(assignedTo != null ? assignedTo : _vm.$t("ticket.unassigned")))])]}},{key:"item.ticketPriorityId",fn:function(ref){
var ticketPriorityId = ref.item.ticketPriorityId;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("ticket.ticketPriorities." + (_vm.getTicketPriority(ticketPriorityId))))))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{name: _vm.detailTo, params: {ticketId: item.id}},"icon":""}},[_c('v-icon',[_vm._v("$visible")])],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }