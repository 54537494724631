<script>
export default {
  data() {
    return {
      paginator: { data: [] },
      pagination: {
        page: 1,
        sortBy: undefined,
        sortDesc: undefined,
        perPage: 10,
        params: undefined,
      },
      loaded: false,
      isLoading: false,
      callTimeout: null,
      error: null,
      overwriteData: true,
    };
  },
  props: {
    params: {
      type: Object,
    },
    indexRequest: {
      type: Function,
      required: true,
    },
  },
  watch: {
    search: {
      handler() {
        if (this.callTimeout !== null) clearTimeout(this.callTimeout);
        this.callTimeout = setTimeout(() => this.reloadPaginator(), 300);
      },
    },
    params() {
      if (this.callTimeout !== null) clearTimeout(this.callTimeout);
      this.callTimeout = setTimeout(() => this.reloadPaginator(), 100);
    },
  },
  methods: {
    /**
     * Update only the page of the pagination object
     * @param page
     */
    updatePaginationPage(page) {
      this.updatePagination({
        page: page,
        sortBy: [this.pagination.sortBy],
        sortDesc: [this.pagination.sortDesc],
        params: this.params,
      });
    },
    /**
     * Update the pagination object and then reload the paginated resource with the new pagination object.
     * With a new sort, or a new page
     * @param sortBy
     * @param sortDesc
     * @param page
     * @param itemsPerPage
     * @param params
     *
     * @TODO this is still a vuetify way of pagination, this should be a generic way. And the vuetify way should be in
     * KCrudTable.vue
     */
    updatePagination({
      sortBy,
      sortDesc,
      page,
      itemsPerPage,
      params,
    }) {
      this.pagination = {
        page: page,
        perPage: itemsPerPage,
        sortBy: sortBy && sortBy.length ? sortBy[0] : undefined,
        sortDesc: sortDesc && sortDesc.length ? sortDesc[0] : undefined,
        params,
      };

      return this.reloadPaginator();
    },
    /**
     * Call the loadPaginator with the page and sorter saved in the pagination object
     */
    reloadPaginator() {
      this.loadPaginator(this.pagination.page, this.pagination.sortBy, this.pagination.sortDesc, this.params);
    },

    async loadPaginator(page, sortBy, sortDesc, params) {
      try {
        this.isLoading = true;
        const requestParameters = [page, this.pagination.perPage, this.search, sortBy || this.sortBy, sortDesc, params];
        const {
          data: {
            data,
            meta,
          },
        } = await this.indexRequest(...requestParameters);
        this.paginator.data = this.overwriteData ? data : [...this.paginator.data, ...data]
        this.paginator.meta = meta

        this.loaded = true; // first time loaded
        this.isLoading = false; // loading changes

        this.pagination.page = this.paginator.meta.currentPage;
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
