var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loaded)?_c('VSkeletonLoader',{attrs:{"type":"table-thead, table-tbody, table-tfoot"}}):_c('v-data-table',_vm._g(_vm._b({class:{'row-pointer': _vm.hasRowClickListener, 'expandable-table': _vm.showExpand},attrs:{"headers":_vm.datatableHeaders,"items":_vm.paginator.data,"loading":_vm.isLoading,"server-items-length":_vm.paginator.meta.total,"showExpand":_vm.showExpand,"footer-props":{itemsPerPageOptions: [5,10,15,25],
                 itemsPerPageText: _vm.$t('global.$vuetify.dataFooter.itemsPerPageText'),
                 pageText: _vm.$t('global.$vuetify.dataFooter.pageText')}},on:{"update:options":_vm.handleUpdatePagination},scopedSlots:_vm._u([_vm._l((_vm.itemSlots),function(_,name){return {key:name,fn:function(props){return [_vm._t(name,null,{"header":props.header,"item":props.item,"value":props.header})]}}}),{key:"item.actions",fn:function(ref){
                 var item = ref.item;
return [_c('div',{staticClass:"crud-actions text-right"},[_vm._t("actions",null,{"item":item}),(_vm.hasClickUpdateListener('edit') && _vm.permissionChecker('update'))?_c('v-btn',{attrs:{"color":"text--secondary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click:edit', item)}}},[_c('v-icon',[_vm._v("$edit")])],1):_vm._e(),(_vm.hasClickUpdateListener('delete') && _vm.permissionChecker('destroy'))?_c('v-btn',{attrs:{"color":"text--secondary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click:delete', item)}}},[_c('v-icon',[_vm._v("$delete")])],1):_vm._e()],2)]}},{key:"expanded-item",fn:function(ref){
                 var headers = ref.headers;
                 var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._t("expanded-item",null,{"item":item})],2)]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }